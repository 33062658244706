// EmailLink.js
import React from 'react';

const EmailLink = () => {
    return (
        <div className="fixed top-0 right-0 p-4 flex space-x-4 items-center">
            <a href="https://mggg.cloud/" className="flex items-center text-gray-500 hover:text-green-600">
                <span className="text-2xl">🖋️</span>
                <h2 className="ml-2">Blogs</h2>
            </a>

            <div className="border-r border-gray-300 h-6"></div> {/* 竖直分割线 */}

            <a href="mailto:admin@mggg.cloud" className="flex items-center text-gray-500 hover:text-green-600">
                <span className="text-2xl">✉️</span>
                <h2 className="ml-2">Report Bugs</h2>
            </a>
        </div>
    );
};





export default EmailLink;
