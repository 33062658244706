import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

import { CloudflareWorkersAI } from "langchain/llms/cloudflare_workersai";

export const model = new CloudflareWorkersAI({
    model: "@cf/meta/llama-2-7b-chat-int8", // Default value
    cloudflareAccountId: "afe2cf41c6b92b459a3b7e502156ca9b",
    //cloudflareAccountId: process.env.CLOUDFLARE_ACCOUNT_ID,
    cloudflareApiToken: "cTPB1JWhwHexAPsGydhFEaB3yIoj1t8i63tSqZns",
    //cloudflareApiToken: process.env.CLOUDFLARE_API_TOKEN,
    // Pass a custom base URL to use Cloudflare AI Gateway
    baseUrl: `https://gateway.ai.cloudflare.com/v1/afe2cf41c6b92b459a3b7e502156ca9b/free-gpt/workers-ai`
});


const UserIcon = () => {
    return <span role="img" aria-label="User">🧑</span>; // Unicode character for person
};

const AiIcon = () => {
    return <span role="img" aria-label="AI">🤖</span>; // Unicode character for robot face
};

const LoadingIndicator = () => {
    return (
        <div className="flex justify-center items-center py-32">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
        </div>
    );
};

const Chat = () => {
    const [input, setInput] = useState('');
    const [messages, setMessages] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        setMessages(["AI Assistant: Can I help you?"]);
    }, []);

    const handleInputChange = (event) => {
        setInput(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        const userMessage = `User: ${input}`;
        setMessages(messages => [...messages, userMessage]);
        setInput('');

        try {
            const response = await fetch('https://api.mggis0or1.workers.dev/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'access_key': `${process.env.REACT_APP_ACCESS_KEY}`
                },
                body: JSON.stringify({messages: [
                    {"role":"system","content":"You are a friendly assistant that helps answer user questions"},
                        {"role":"user","content": input}]})
            });

            if (response.ok) {
                const data = await response.json();
                setMessages(messages => [...messages, `AI Assistant: ${data.result.response}`]);
            } else {
                console.error('Response Error:', response.status);
                setMessages(messages => [...messages, `AI Assistant: Error in processing request`]);
            }
        } catch (error) {
            console.error('Network Error:', error);
            setMessages(messages => [...messages, `AI Assistant: Error in processing request`]);
        }

        setIsSubmitting(false);
    };

    return (
        <div className="min-h-screen bg-gray-100 p-4">
            <div className="max-w-xl mx-auto">
                <h2 className="text-xl font-semibold text-center text-gray-800 mb-4 py-2">Free Chat</h2>
                <p className="text-center text-gray-600 text-sm">powered by <span className="font-semibold text-blue-600">llama-2-7b-chat-int8</span></p>
                <div className="bg-white shadow rounded-lg p-4 mb-4 max-h-xl my-2">
                    <div className="overflow-y-auto h-96 mb-4">
                        {messages.map((message, index) => {
                            const isUser = message.startsWith('User:');
                            const messageContent = message.replace('User:', '').replace('AI Assistant:', '');

                            return (
                                <div key={index} className={`flex items-center ${isUser ? 'justify-end' : 'justify-start'} my-2`}>
                                    <span className={`flex items-center ${isUser ? 'flex-row-reverse' : ''}`}>
                                        <span className={`text-2xl ${isUser ? 'ml-2' : 'mr-2'}`}>
                                            {isUser ? <UserIcon /> : <AiIcon />}
                                        </span>
                                        <span className={`inline-block p-2 rounded-lg text-sm ${isUser ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}>
                                                {isUser ? messageContent : <ReactMarkdown className="markdown">{messageContent}</ReactMarkdown>}
                                        </span>
                                    </span>
                                </div>
                            );
                        })}
                        {isSubmitting && <LoadingIndicator />} {/* 在这里添加加载指示器 */}
                    </div>
                    <form onSubmit={handleSubmit} className="flex items-center">
                        <input
                            type="text"
                            value={input}
                            onChange={handleInputChange}
                            placeholder="Type your message..."
                            className="flex-1 border border-gray-300 rounded-full p-2 mr-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            disabled={isSubmitting}
                        />
                        <button
                            type="submit"
                            className={`bg-blue-500 text-white rounded-full px-6 py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-50 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Loading...' : 'Send'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Chat;
