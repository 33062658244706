import React, { useState, useEffect } from 'react';
import Chat from './chat';
import ImageGenerator from "./text2image";
import EmailLink from "./email";

/* global adsbygoogle */

function App() {
    const [activeTab, setActiveTab] = useState('imageGenerator');

    useEffect(() => {
        const scriptId = 'adsbygoogle-script';
        if (!document.getElementById(scriptId)) {
            let adsbygoogleScript = document.createElement('script');
            adsbygoogleScript.id = scriptId; // Assign a unique ID to the script tag
            adsbygoogleScript.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9875499924804850";
            adsbygoogleScript.async = true;
            adsbygoogleScript.crossOrigin = "anonymous";
            document.head.appendChild(adsbygoogleScript);

            adsbygoogleScript.onload = () => {
                // Initialize adsbygoogle
                try {
                    (window.adsbygoogle = window.adsbygoogle || []).push({});
                } catch (e) {
                    console.error('Adsense error:', e);
                }
            };
        }

        // Cleanup function to remove the script when the component unmounts
        return () => {
            const existingScript = document.getElementById(scriptId);
            if (existingScript) {
                existingScript.remove();
            }
        };
    }, []);

    const renderContent = () => {
        switch (activeTab) {
            case 'imageGenerator':
                return <ImageGenerator />;
            case 'chat':
                return <Chat />;
            default:
                return <ImageGenerator />;
        }
    };

    return (
        <div className="flex flex-col md:flex-row h-screen bg-gray-100">

            <div className="w-full md:w-1/5 bg-white p-4 md:p-5">
                <img src="/logo.png" alt="Logo" className="mb-5 mx-auto md:mx-0" />
                <ul className="space-y-2">
                    <li
                        className={`flex items-center p-2 cursor-pointer ${activeTab === 'imageGenerator' ? 'bg-blue-500 text-white' : 'text-gray-700 hover:bg-gray-200'}`}
                        onClick={() => setActiveTab('imageGenerator')}
                    >
                        <span className="mr-2">🌄</span> Text to Image
                    </li>
                    <hr className="border-t border-gray-300" />
                    <li
                        className={`flex items-center p-2 cursor-pointer ${activeTab === 'chat' ? 'bg-blue-500 text-white' : 'text-gray-700 hover:bg-gray-200'}`}
                        onClick={() => setActiveTab('chat')}
                    >
                        <span className="mr-2">💬</span> Chat
                    </li>
                </ul>

                {/* Ad container */}
                <div className="ad-container mt-4 w-full">
                    <ins className="adsbygoogle"
                         style={{ display: 'block' }}
                         data-ad-client="ca-pub-2952911441100165"
                         data-ad-slot="8697173042"
                         data-ad-format="auto"
                         data-full-width-responsive="true"></ins>
                </div>
            </div>

            <div className="flex-grow p-5 overflow-y-auto">
                {renderContent()}
            </div>

            <EmailLink />
        </div>
    );
}

export default App;
