import React, { useState, useEffect } from 'react';

const LoadingIndicator = () => {
    return (
        <div className="flex justify-center items-center py-32">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
        </div>
    );
};

const ImageGenerator = () => {
    const [prompt, setPrompt] = useState('');
    const [generatedImage, setGeneratedImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (event) => {
        setPrompt(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setGeneratedImage(null); // 添加这行来在每次提交时重置图片

        try {
            const response = await fetch('https://api.mggis0or1.workers.dev/generate-image', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'access_key': `${process.env.REACT_APP_ACCESS_KEY}` // 使用环境变量中的访问密钥
                },
                body: JSON.stringify({ prompt })
            });

            if (response.ok) {
                const blob = await response.blob();
                setGeneratedImage(URL.createObjectURL(blob));
            } else {
                console.error('Response Error:', response.status);
            }
        } catch (error) {
            console.error('Network Error:', error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        return () => {
            if (generatedImage) {
                URL.revokeObjectURL(generatedImage);
            }
        };
    }, [generatedImage]);

    return (
        <div className="flex justify-center items-center h-screen bg-gray-100">
            <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-md">
                <h1 className="text-xl font-bold text-center mb-2">Text to Image</h1>
                <p className="text-center text-sm mb-4 text-gray-600">
                    powered by <span className="font-semibold text-blue-600">stable-diffusion-xl-base-1.0</span>
                </p>
                <form onSubmit={handleSubmit} className="flex flex-col items-center space-y-4">
                    <input
                        type="text"
                        value={prompt}
                        onChange={handleInputChange}
                        placeholder="Enter a prompt..."
                        className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        disabled={isLoading}
                    />
                    <button
                        type="submit"
                        className={`w-full bg-blue-500 text-white rounded px-4 py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-50 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Generating...' : 'Generate'}
                    </button>
                </form>
                {isLoading && <LoadingIndicator />}
                {generatedImage && <img src={generatedImage} alt="Generated" className="mt-4 max-w-full h-auto rounded-md" />}
            </div>
        </div>
    );
};

export default ImageGenerator;
